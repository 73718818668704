/* colors */

@each $name, $color in $colors {
  .#{$name} {
    color: $color;
  }
}

@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
}