.ratio {
  height: 0;
  position: relative;

  &--3x1 {
    padding-top: 33.33%;
  }

  &--16x9 {
    padding-top: 56.25%;
  }

  &--9x16 {
    padding-top: 177.77%;
  }

  &--5x4 {
    padding-top: 80%;
  }

  &--4x5 {
    padding-top: 125%;
  }

  &--4x3 {
    padding-top: 75%;
  }

  &--3x4 {
    padding-top: 133.33%;
  }

  &--6x4 {
    padding-top: 66.6%;
  }

  &--4x6 {
    padding-top: 150%;
  }

  &--8x5 {
    padding-top: 62.5%;
  }

  &--5x8 {
    padding-top: 160%;
  }

  &--7x5 {
    padding-top: 71.42%;
  }

  &--5x7 {
    padding-top: 140%;
  }

  &--1x1 {
    padding-top: 100%;
  }

  &__object {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
