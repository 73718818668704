.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.fix {
  position: fixed;
}

.sticky {
  position: sticky;
}

.top,
.fill-y,
.fill {
  top: 0;
}

.bottom,
.fill-y,
.fill {
  bottom: 0;
}

.left,
.fill-x,
.fill {
  left: 0;
}

.right,
.fill-x,
.fill {
  right: 0;
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    .#{$prefix}\:rel {
      position: relative;
    }

    .#{$prefix}\:abs {
      position: absolute;
    }

    .#{$prefix}\:fix {
      position: fixed;
    }

    .#{$prefix}\:sticky {
      position: sticky;
    }

    .#{$prefix}\:top,
    .#{$prefix}\:fill-y,
    .#{$prefix}\:fill {
      top: 0;
    }

    .#{$prefix}\:bottom,
    .#{$prefix}\:fill-y,
    .#{$prefix}\:fill {
      bottom: 0;
    }

    .#{$prefix}\:left,
    .#{$prefix}\:fill-x,
    .#{$prefix}\:fill {
      left: 0;
    }

    .#{$prefix}\:right,
    .#{$prefix}\:fill-x,
    .#{$prefix}\:fill {
      right: 0;
    }
  }
}
