* {
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  font-family: 'Tiempos Text', sans-serif;
  width: 100%;
  height: 100%;
}

::selection {
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
