// font-sizes
$font-sizes: 2.1rem, 2.4rem, 3.8rem;

// breakpoints
$sm-viewport: 'min-width: 48em';
$md-viewport: 'min-width: 64em';
$lg-viewport: 'min-width: 75em';

$breakpoints: (
  sm: $sm-viewport,
  md: $md-viewport,
  lg: $lg-viewport,
);

/* colors */
$mid-gray: #32444e;
$mid-blue: #d2e5ef;
$pink: #ebc7ad;
$dark-blue: #1b5162;
$brick: #cd7a5d;
$dark-gray: #3d434c;
$green-gray: #3c4e53;
$light-blue: #bccfd4;

$colors: (
  mid-gray: $mid-gray,
  mid-blue: $mid-blue,
  pink: $pink,
  dark-blue: $dark-blue,
  brick: $brick,
  dark-gray: $dark-gray,
  green-gray: $green-gray,
  light-blue: $light-blue,
);

/* easings */
$ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-quart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-sine: cubic-bezier(0.47, 0, 0.745, 0.715);
$ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-out-sine: cubic-bezier(0.39, 0.575, 0.565, 1);
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-circ: cubic-bezier(0.075, 0.82, 0.165, 1);
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-in-out-quart: cubic-bezier(0.77, 0, 0.175, 1);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$ease-in-out-sine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$ease-in-out-expo: cubic-bezier(1, 0, 0, 1);
$ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);
