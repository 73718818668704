.f {
  display: flex;
}

.fw {
  flex-wrap: wrap;
}

.fr {
  flex-direction: row;
}

.frr {
  flex-direction: row-reverse;
}

.fc {
  flex-direction: column;
}

.ais {
  align-items: flex-start;
}

.aie {
  align-items: flex-end;
}

.aic {
  align-items: center;
}

.aib {
  align-items: baseline;
}

.acc {
  align-content: center;
}

.jcs {
  justify-content: flex-start;
}

.jce {
  justify-content: flex-end;
}

.jcc {
  justify-content: center;
}

.jcb {
  justify-content: space-between;
}

.fa {
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
}

.fn {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    .#{$prefix}\:f {
      display: flex;
    }

    .#{$prefix}\:fw {
      flex-wrap: wrap;
    }

    .#{$prefix}\:fr {
      flex-direction: row;
    }

    .#{$prefix}\:frr {
      flex-direction: row-reverse;
    }

    .#{$prefix}\:fc {
      flex-direction: column;
    }

    .#{$prefix}\:ais {
      align-items: flex-start;
    }

    .#{$prefix}\:aie {
      align-items: flex-end;
    }

    .#{$prefix}\:aic {
      align-items: center;
    }

    .#{$prefix}\:aib {
      align-items: baseline;
    }
    
    .#{$prefix}\:acc {
      align-content: center;
    }

    .#{$prefix}\:jcs {
      justify-content: flex-start;
    }

    .#{$prefix}\:jce {
      justify-content: flex-end;
    }

    .#{$prefix}\:jcc {
      justify-content: center;
    }

    .#{$prefix}\:jcb {
      justify-content: space-between;
    }

    .#{$prefix}\:fa {
      flex: 1 1 auto;
      min-width: 0;
      min-height: 0;
    }

    .#{$prefix}\:fn {
      -webkit-box-flex: 0;
      -webkit-flex: none;
      -ms-flex: none;
      flex: none;
    }
  }
}
