@font-face {
  font-family: 'Beatrice';
  src: url('../assets/fonts/Beatrice-Regular.woff2') format('woff2'),
    url('../assets/fonts/Beatrice-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tiempos Text';
  src: url('../assets/fonts/TiemposTextWeb-Regular.woff2') format('woff2'),
    url('../assets/fonts/TiemposTextWeb-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
