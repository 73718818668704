.wct {
  will-change: transform;
}

.wco {
  will-change: opacity;
}

.wcto {
  will-change: transform, opacity;
}
