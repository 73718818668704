.db {
  display: block;
}

.dib {
  display: inline-block;
}

.di {
  display: inline;
}

.dn {
  display: none;
}

.dc {
  display: contents;
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    .#{$prefix}\:db {
      display: block;
    }

    .#{$prefix}\:dib {
      display: inline-block;
    }

    .#{$prefix}\:di {
      display: inline;
    }

    .#{$prefix}\:dn {
      display: none;
    }

    .#{$prefix}\:dc {
      display: contents;
    }
  }
}
