.ttu {
  text-transform: uppercase;
}

.tdu {
  text-decoration: underline;
}

.tdn {
  text-decoration: none;
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.tj {
  text-align: justify;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    .#{$prefix}\:ttu {
      text-transform: uppercase;
    }

    .#{$prefix}\:tdu {
      text-decoration: underline;
    }

    .#{$prefix}\:tdn {
      text-decoration: none;
    }

    .#{$prefix}\:tc {
      text-align: center;
    }

    .#{$prefix}\:tl {
      text-align: left;
    }

    .#{$prefix}\:tr {
      text-align: right;
    }

    .#{$prefix}\:tj {
      text-align: justify;
    }

    .#{$prefix}\:truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
