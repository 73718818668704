@mixin fluid($properties, $min-vw, $max-vw, $min-value, $max-value) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} +
          #{strip-unit($max-value - $min-value)} *
          (100vw - #{$min-vw}) /
          #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @media (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}

@mixin visually-hidden() {
  // stylelint-disable-next-line
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
}

@mixin visually-shown($position: inherit) {
  // stylelint-disable-next-line
  position: $position !important;
  overflow: auto;
  width: auto;
  height: auto;
  margin: 0;
  clip: auto;
}