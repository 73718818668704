button,
.button {
  border: none;
  border-radius: 0px;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: inherit;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  white-space: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  outline: unset;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  color: currentColor;
  text-decoration: none;
}

input[type='email'] {
  outline: unset;
  border-radius: unset;
  appearance: none;

  // &::-webkit-input-placeholder {
  //   color: gray !important;
  // }

  // &:-moz-placeholder {
  //   /* Firefox 18- */
  //   color: gray !important;
  // }

  // &::-moz-placeholder {
  //   /* Firefox 19+ */
  //   color: gray !important;
  // }

  // &:-ms-input-placeholder {
  //   color: gray !important;
  // }

  // &:focus {
  //   &::-webkit-input-placeholder {
  //     color: $black !important;
  //   }

  //   &:-moz-placeholder {
  //     /* Firefox 18- */
  //     color: $black !important;
  //   }

  //   &::-moz-placeholder {
  //     /* Firefox 19+ */
  //     color: $black !important;
  //   }

  //   &:-ms-input-placeholder {
  //     color: $black !important;
  //   }
  // }
}
