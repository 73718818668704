@each $size in $font-sizes {
  $suffix: strip-unit($size) * 10;
  .f#{$suffix} {
    font-size: $size;
  }
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @each $size in $font-sizes {
      $suffix: strip-unit($size) * 10;
      .#{$prefix}\:f#{$suffix} {
        font-size: $size;
      }
    }
  }
}
