.x {
  width: 100%;
}

.y {
  height: 100%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.third {
  width: calc(100% / 3);
}

.w35 {
  width: 35%;
}

.w40 {
  width: 40%;
}

.w45 {
  width: 45%;
}

.w50 {
  width: 50%;
}

.w55 {
  width: 55%;
}

.w60 {
  width: 60%;
}

.w65 {
  width: 65%;
}

.two-thirds {
  width: calc(100% * 2 / 3);
}

.w70 {
  width: 70%;
}

.w75 {
  width: 75%;
}

.w80 {
  width: 80%;
}

.w85 {
  width: 85%;
}

.w90 {
  width: 90%;
}

.w95 {
  width: 95%;
}

.min-vw100 {
  min-width: 100vw;
}

.min-vh100 {
  min-height: 100vh;
}

.max-w1440 {
  max-width: 1440px;
}

.vw100 {
  width: 100vw;
}

.vh100 {
  height: 100vh;
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    .#{$prefix}\:x {
      width: 100%;
    }

    .#{$prefix}\:y {
      height: 100%;
    }

    .#{$prefix}\:w25 {
      width: 25%;
    }

    .#{$prefix}\:w30 {
      width: 30%;
    }

    .#{$prefix}\:third {
      width: calc(100% / 3);
    }

    .#{$prefix}\:w35 {
      width: 35%;
    }

    .#{$prefix}\:w40 {
      width: 40%;
    }

    .#{$prefix}\:w45 {
      width: 45%;
    }

    .#{$prefix}\:w50 {
      width: 50%;
    }

    .#{$prefix}\:w55 {
      width: 55%;
    }

    .#{$prefix}\:w60 {
      width: 60%;
    }

    .#{$prefix}\:w65 {
      width: 65%;
    }

    .#{$prefix}\:two-thirds {
      width: calc(100% * 2 / 3);
    }

    .#{$prefix}\:w70 {
      width: 70%;
    }

    .#{$prefix}\:w75 {
      width: 75%;
    }

    .#{$prefix}\:w80 {
      width: 80%;
    }

    .#{$prefix}\:w85 {
      width: 85%;
    }

    .#{$prefix}\:w90 {
      width: 90%;
    }

    .#{$prefix}\:w95 {
      width: 95%;
    }

    .#{$prefix}\:min-vw100 {
      min-width: 100vw;
    }

    .#{$prefix}\:min-vh100 {
      min-height: 100vh;
    }

    .#{$prefix}\:vw100 {
      width: 100vw;
    }

    .#{$prefix}\:vh100 {
      height: 100vh;
    }
  }
}
