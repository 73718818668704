@for $count from 0 through 20 {
  .p#{$count * 8},
  .ph#{$count * 8},
  .pr#{$count * 8} {
    padding-right: ($count * 8 / 10) * 1rem;
  }

  .p#{$count * 8},
  .ph#{$count * 8},
  .pl#{$count * 8} {
    padding-left: ($count * 8 / 10) * 1rem;
  }

  .p#{$count * 8},
  .pv#{$count * 8},
  .pt#{$count * 8} {
    padding-top: ($count * 8 / 10) * 1rem;
  }

  .p#{$count * 8},
  .pv#{$count * 8},
  .pb#{$count * 8} {
    padding-bottom: ($count * 8 / 10) * 1rem;
  }
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    @for $count from 0 through 20 {
      .#{$prefix}\:p#{$count * 8},
      .#{$prefix}\:ph#{$count * 8},
      .#{$prefix}\:pr#{$count * 8} {
        padding-right: ($count * 8 / 10) * 1rem;
      }

      .#{$prefix}\:p#{$count * 8},
      .#{$prefix}\:ph#{$count * 8},
      .#{$prefix}\:pl#{$count * 8} {
        padding-left: ($count * 8 / 10) * 1rem;
      }

      .#{$prefix}\:p#{$count * 8},
      .#{$prefix}\:pv#{$count * 8},
      .#{$prefix}\:pt#{$count * 8} {
        padding-top: ($count * 8 / 10) * 1rem;
      }

      .#{$prefix}\:p#{$count * 8},
      .#{$prefix}\:pv#{$count * 8},
      .#{$prefix}\:pb#{$count * 8} {
        padding-bottom: ($count * 8 / 10) * 1rem;
      }
    }
  }
}
