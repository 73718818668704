.ma,
.mha,
.mra {
  margin-right: auto;
}

.ma,
.mha,
.mla {
  margin-left: auto;
}

.ma,
.mva,
.mta {
  margin-top: auto;
}

.ma,
.mva,
.mba {
  margin-bottom: auto;
}

.mt6 {
  margin-top: 6px;
}

@for $count from 0 through 20 {
  .m#{$count * 8},
  .mh#{$count * 8},
  .mr#{$count * 8} {
    margin-right: ($count * 8 / 10) * 1rem;
  }

  .m#{$count * 8},
  .mh#{$count * 8},
  .ml#{$count * 8} {
    margin-left: ($count * 8 / 10) * 1rem;
  }

  .m#{$count * 8},
  .mv#{$count * 8},
  .mt#{$count * 8} {
    margin-top: ($count * 8 / 10) * 1rem;
  }

  .m#{$count * 8},
  .mv#{$count * 8},
  .mb#{$count * 8} {
    margin-bottom: ($count * 8 / 10) * 1rem;
  }
}

@each $prefix, $query in $breakpoints {
  @media ($query) {
    .#{$prefix}\:ma,
    .#{$prefix}\:mha,
    .#{$prefix}\:mra {
      margin-right: auto;
    }

    .#{$prefix}\:ma,
    .#{$prefix}\:mha,
    .#{$prefix}\:mla {
      margin-left: auto;
    }

    .#{$prefix}\:ma,
    .#{$prefix}\:mva,
    .#{$prefix}\:mta {
      margin-top: auto;
    }

    .#{$prefix}\:ma,
    .#{$prefix}\:mva,
    .#{$prefix}\:mba {
      margin-bottom: auto;
    }

    @for $count from 0 through 20 {
      .#{$prefix}\:m#{$count * 8},
      .#{$prefix}\:mh#{$count * 8},
      .#{$prefix}\:mr#{$count * 8} {
        margin-right: ($count * 8 / 10) * 1rem;
      }

      .#{$prefix}\:m#{$count * 8},
      .#{$prefix}\:mh#{$count * 8},
      .#{$prefix}\:ml#{$count * 8} {
        margin-left: ($count * 8 / 10) * 1rem;
      }

      .#{$prefix}\:m#{$count * 8},
      .#{$prefix}\:mv#{$count * 8},
      .#{$prefix}\:mt#{$count * 8} {
        margin-top: ($count * 8 / 10) * 1rem;
      }

      .#{$prefix}\:m#{$count * 8},
      .#{$prefix}\:mv#{$count * 8},
      .#{$prefix}\:mb#{$count * 8} {
        margin-bottom: ($count * 8 / 10) * 1rem;
      }
    }
  }
}
