.portrait {
  display: none;

  @media (orientation: portrait) {
    display: block;
  }
}

.landscape {
  display: none;

  @media (orientation: landscape) {
    display: block;
  }
}
